.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg {
  color: #333;
  transition: all 0.3s;
  background-color: #fff;
}

.playing {
  color: #fff;
  background-color: #333;
}

.playing button {
  border-color: #fff;
}

.title {
  top: 24%;
  position: absolute;
  user-select: none;
}

span {
  margin: 0;
  font-size: 1.8rem;
  font-weight: lighter;
  letter-spacing: 1rem;
}

.now {
  bottom: 10%;
  position: absolute;
  user-select: none;
  font-weight: lighter;
  letter-spacing: 0.2rem;
  background-color: transparent;
}

.btn {
  width: 64px;
  height: 64px;
  cursor: pointer;
  border: 2px solid #333;
  outline: none;
  transition: all 0.5s;
  user-select: none;
  border-radius: 32px;
  background-color: transparent;
}
